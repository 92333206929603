<template>
  <div class="cont">
    <Spin fix v-if="loading"></Spin>
    <div class="box">
      <Form ref="create" :model="form">
        <FormItem label="Company Description" prop="company_description">
          <Input
            v-if="is_administrator"
            type="textarea"
            :rows="4"
            v-model="form.company_description"
          />
          <div style="width:100%; display:inline-block;" v-else>
            {{ form.company_description }}
          </div>
        </FormItem>

        <FormItem
          v-if="permission.includes('myCompany.informationPanelAndImage')"
          label="Information Panel"
          prop="information_panel"
        >
          <div style="display:inline-block;">
            <quill-editor
              v-if="is_administrator"
              style="height:300px;"
              v-model="form.information_panel"
              ref="myQuillEditor"
            >
            </quill-editor>
            <div
              style="width:100%; display:inline-block;"
              v-else
              v-html="form.information_panel"
            ></div>
          </div>
        </FormItem>

        <FormItem
          v-if="permission.includes('myCompany.informationPanelAndImage')"
          label="Panel Image"
          prop="information_panel_image"
        >
          <div v-if="is_administrator">
            <div class="upload" v-if="imageUploading">
              <div class="upload-inner">
                <Icon class="icon" type="ios-loading" size="52"></Icon>
                <p>Uploading...</p>
              </div>
            </div>
            <div v-else class="upload" @click="$refs.imageFile.click()">
              <div class="upload-inner">
                <input
                  @change="handleUpload($event, 'information_panel_image')"
                  v-show="false"
                  type="file"
                  ref="imageFile"
                  accept="image/*"
                />
                <Icon class="icon" type="ios-cloud-upload" size="52"></Icon>
                <p>Click here to upload</p>
              </div>
            </div>
            <div
              v-if="form.information_panel_image.length"
              style="margin: 20px 0;"
            >
              <div
                class="panelImg"
                v-for="(img, index) in form.information_panel_image"
                :key="index"
              >
                <img :src="img" alt="" />
                <p class="close" @click="minusImg(index)">×</p>
              </div>
            </div>
            <!-- <div class="upload" v-else>
              <div v-if="imageUploading">
                Uploading...
              </div>
              <div v-else>
                <div v-if="form.information_panel_image">
                  <img
                    v-if="form.information_panel_image"
                    style="display:block;width:200px;"
                    :src="form.information_panel_image"
                    alt=""
                  />
                  <Button
                    class="mt-10"
                    @click="removeFile('information_panel_image')"
                    >Choose Again</Button
                  >
                </div>
              </div>
            </div> -->
          </div>
          <div v-else style="width:100%; display:inline-block;">
            <img
              v-if="form.information_panel_image"
              style="display:block;width:200px;"
              :src="form.information_panel_image"
              alt=""
            />
            <span v-else>empty</span>
          </div>
        </FormItem>

        <FormItem
          v-if="permission.includes('myCompany.uploadVideo')"
          label="Video"
          prop="video"
        >
          <div v-if="is_administrator">
            <div
              v-if="!(videoFile || form.video)"
              class="upload"
              @click="$refs.videoFile.click()"
            >
              <div class="upload-inner">
                <input
                  @change="handleUpload($event, 'video')"
                  v-show="false"
                  type="file"
                  ref="videoFile"
                  accept="video/*"
                />
                <Icon class="icon" type="ios-cloud-upload" size="52"></Icon>
                <p>Click here to upload</p>
              </div>
            </div>
            <div class="upload" v-else>
              <div v-if="videoUploading">
                Uploading...
              </div>
              <div v-else>
                <div v-if="form.video">
                  <video
                    controls
                    v-if="form.video"
                    style="display:block;max-width:500px;width: 100%;"
                    :src="form.video"
                  />
                  <Button class="mt-10" @click="removeFile('video')"
                    >Choose Again</Button
                  >
                </div>
              </div>
            </div>
          </div>

          <div v-else style="width:100%; display:inline-block;">
            <video
              controls
              v-if="form.video"
              style="display:block;width:500px;width: 100%;"
              :src="form.video"
            />
            <span v-else>empty</span>
          </div>
        </FormItem>

        <Button
          v-if="is_administrator"
          :loading="createLoading"
          type="primary"
          @click="createSubmit"
          >Save Changes</Button
        >
      </Form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import api from "@/api";
const { _editMyCompanyDescAndOthers, _queryMyCompany } = api;
import S3 from "aws-s3";

const uploader = new S3({
  bucketName: "connexima",
  dirName: "documents",
  region: "us-west-1",
  accessKeyId: "AKIA6KR6UZLLVZYGDEZM",
  secretAccessKey: "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"
});

export default {
  name: "documents",
  created() {
    this.queryMyCompany();
  },
  computed: {
    ...mapState("user", ["is_administrator"])
  },
  data() {
    return {
      permission: [],

      loading: false,
      imageUploading: false,
      imageFile: null,
      videoUploading: false,
      videoFile: null,

      form: {
        company_description: "",
        information_panel: "",
        information_panel_image: [],
        video: ""
      },
      createLoading: false
    };
  },

  methods: {
    queryMyCompany() {
      this.loading = true;
      _queryMyCompany()
        .then(response => {
          const { data } = response;

          this.form.company_description = data.company_description;
          this.form.information_panel = data.information_panel;
          this.form.information_panel_image = data.information_panel_image.map(
            item => item.url
          );
          this.form.video = data.video?.url;

          this.permission = data.company_permission.map(item => item.name);
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    removeFile(type) {
      if (type === "video") {
        this.form.video = "";
        this.videoFile = null;
      }

      if (type === "information_panel_image") {
        this.form.information_panel_image = [];
        this.imageFile = null;
      }
    },

    handleUpload(e, type) {
      const file = e.target.files[0];
      if (file) {
        if (file.size > 20 * 1024 * 1024) {
          this.$Message.error("File size cannot exceed 20MB");
        } else {
          if (type === "video") {
            this.videoFile = file;
          }

          if (type === "information_panel_image") {
            this.imageFile = file;
          }
          this.upload(type);
        }
      }
    },

    upload(type) {
      let file;
      if (type === "video") {
        this.videoUploading = true;
        file = this.videoFile;
      }

      if (type === "information_panel_image") {
        this.imageUploading = true;
        file = this.imageFile;
      }

      uploader
        .uploadFile(file)
        .then(data => {
          this.$Message.success("upload success!");
          if (type === "video") {
            this.form.video = data.location;
          }

          if (type === "information_panel_image") {
            this.form.information_panel_image.push(data.location);
          }
        })
        .catch(() => {
          this.$Message.error("upload error");
        })
        .finally(() => {
          if (type === "video") {
            this.videoUploading = false;
          }

          if (type === "information_panel_image") {
            this.imageUploading = false;
          }
        });
    },

    createSubmit() {
      this.$refs.create.validate(valid => {
        if (valid) {
          this.createLoading = true;
          const cloneForm = {
            ...this.form
          };

          if (!this.permission.includes("myCompany.informationPanelAndImage")) {
            delete cloneForm.information_panel_image;
          }
          if (!this.permission.includes("myCompany.uploadVideo")) {
            delete cloneForm.video;
          }

          _editMyCompanyDescAndOthers(cloneForm)
            .then(() => {
              this.$Message.success("success");
            })
            .catch(({ message }) => {
              this.$Message.error(message);
            })
            .finally(() => {
              this.createLoading = false;
            });
        }
      });
    },
    minusImg(index) {
      this.form.information_panel_image.splice(index, 1);
    }
  }
};
</script>

<style lang="less" scoped>
.panelImg {
  display: inline-block;
  position: relative;
  height: 80px;
  margin-right: 10px;
  width: auto;
  min-width: 20px;
  border: 1px solid #f2f2f2;
  img {
    height: 100%;
    text-align: center;
  }
  .close {
    position: absolute;
    top: -8px;
    right: -6px;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    line-height: 13px;
    text-align: center;
    color: red;
    border: 1px solid red;
    cursor: pointer;
  }
}
.cont {
  background: #fff;
  .box {
    .upload {
      width: 100%;
      display: inline-block;
      border: 1px solid #ccc;
      border-radius: 3px;
      padding: 20px;
      cursor: pointer;

      .upload-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .icon {
          color: @primary-color;
        }
      }
    }
  }
}
</style>
