<template>
  <div>
    <detail-attendee v-if="role === 'attendee'" />
    <detail-exhibitor v-if="role === 'exhibitor'" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import detailAttendee from "./detail-attendee";
import detailExhibitor from "./detail-exhibitor";

export default {
  components: {
    detailAttendee,
    detailExhibitor
  },
  computed: {
    ...mapState("user", ["role"])
  },
  data() {
    return {};
  }
};
</script>
