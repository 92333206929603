<template>
  <div class="cont">
    <Spin fix v-if="loading"></Spin>
    <div class="box">
      <Form ref="create" :model="form">
        <FormItem label="Company Description" prop="company_description">
          <Input
            v-if="is_administrator"
            type="textarea"
            :rows="4"
            v-model="form.company_description"
          />
          <div style="width:100%; display:inline-block;" v-else>
            {{ form.company_description }}
          </div>
        </FormItem>

        <Button
          v-if="is_administrator"
          :loading="createLoading"
          type="primary"
          @click="createSubmit"
          >Save Changes</Button
        >
      </Form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import api from "@/api";
const { _editMyCompanyDescAndOthers, _queryMyCompany } = api;

export default {
  created() {
    this.queryMyCompany();
  },
  computed: {
    ...mapState("user", ["is_administrator"])
  },
  data() {
    return {
      loading: false,

      form: {
        company_description: ""
      },

      createLoading: false
    };
  },

  methods: {
    queryMyCompany() {
      this.loading = true;
      _queryMyCompany()
        .then(response => {
          const { data } = response;
          this.form.company_description = data.company_description;
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    createSubmit() {
      this.$refs.create.validate(valid => {
        if (valid) {
          this.createLoading = true;
          _editMyCompanyDescAndOthers({
            ...this.form
          })
            .then(() => {
              this.$Message.success("success");
            })
            .catch(({ message }) => {
              this.$Message.error(message);
            })
            .finally(() => {
              this.createLoading = false;
            });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.cont {
  background: #fff;
  .box {
    .upload {
      width: 100%;
      display: inline-block;
      border: 1px solid #ccc;
      border-radius: 3px;
      padding: 20px;
      cursor: pointer;

      .upload-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .icon {
          color: @primary-color;
        }
      }
    }
  }
}
</style>
